// Import your components
import LogIn from '@/views/LogIn.vue';
import Tournament from "@/views/Tournament.vue";
import Leaderboard from "@/views/Leaderboard.vue";
import AboutUs from "@/views/AboutUs.vue";
import Shop from "@/views/Shop.vue";
import Profile from "@/views/Profile.vue";
import Faq from "@/views/Faq.vue";
import AffiliateProgram from "@/views/AffiliateProgram.vue";
import Prizes from "@/views/Prizes.vue";
import Home from "@/views/Home.vue";
import ComingSoon from "@/views/ComingSoon.vue";
import ContactUs from "@/views/ContactUs.vue";
import NotFound from "@/views/NotFound.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsAndConditions from "@/views/TermsAndConditions.vue";
import DeleteAccount from "@/views/DeleteAccount.vue";
// Import other components as needed
import { createRouter, createWebHistory } from 'vue-router';
import Play from "@/views/Play.vue";
var routes = [
    { path: '/', component: Home },
    { path: '/login', component: LogIn },
    { path: '/tournament', component: Tournament },
    { path: '/leaderboard', component: Leaderboard },
    { path: '/shop', component: Shop },
    { path: '/about', component: AboutUs },
    { path: '/profile', component: Profile },
    { path: '/faq', component: Faq },
    { path: '/affiliate', component: AffiliateProgram },
    { path: '/prizes', component: Prizes },
    { path: '/soon', component: ComingSoon },
    { path: '/contact', component: ContactUs },
    { path: '/terms_and_conditions', component: TermsAndConditions },
    { path: '/privacy_policy', component: PrivacyPolicy },
    { path: '/delete_account', component: DeleteAccount },
    { path: '/play', component: Play },
    { path: '/:pathMatch(.*)', component: NotFound },
    // Add more routes as needed
];
var router = createRouter({
    history: createWebHistory(),
    routes: routes
});
export function setupRouter(app) {
    app.use(router);
}
export default router;
