<template>
  <div id="app">
    <div class="main-loading" v-if="loading">
      <div class="lds-dual-ring"></div>
    </div>
    <div
      class="pages"
      v-if="!loading"
      :class="store.state.module.popup != '' ? 'overlay' : ''"
    >
      <MainMenu></MainMenu>
      <router-view></router-view>
      <FooterBlock></FooterBlock>
    </div>

    <!--<img alt="Vue logo" src="./assets/logo.png">-->

    <div
      class="darken"
      v-if="store.state.module.popup != '' || store.state.module.error != ''"
      @click="[(store.state.module.popup = ''), store.commit('setError', '')]"
    ></div>
    <LoginPopup></LoginPopup>
    <PlayPopup></PlayPopup>
    <ErrorPopup></ErrorPopup>
    <div class="cookies-modal" v-if="!agree">
      <div class="col-np-8 col-np-md-8 col-np-xs-8">
        <p>By using this website you agree to 
          <span @click="store.commit('goTo', '/terms_and_conditions')">Terms and Conditions</span> and 
          <span @click="store.commit('goTo', '/privacy_policy')">Privacy Policy</span></p>
      </div>
      <div class="cookies-confirm">
        <span @click="agreeTerms()">Understood</span>
      </div>
    </div>
    <div class="spinner" v-if="store.state.module.loading">
      <div class="lds-dual-ring"></div>
    </div>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import Vue from "vue";
import MainMenu from "@/components/MainMenu.vue";
import LoginPopup from "@/components/LoginPopup.vue";
import store from "@/store";
import PlayPopup from "@/components/PlayPopup.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import Cookies from "js-cookie";
import { getUserProfile } from "@/api";
import ErrorPopup from "@/components/ErrorPopup.vue";
import {parser }from 'ua-parser-js';
import {UAParser }from "ua-parser-js";

//import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/vue";

/*const projectId = "bde8bbfcaccd519c1a11d5bafb1d7004";

const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};

const metadata = {
  name: "Miner's Rush",
  description: "Miner's Rush",
  url: "https://minersrush.xyz/",
  icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  metadata,

  enableEIP6963: false, // true by default
  enableInjected: true, // true by default
  enableCoinbase: false, // true by default
  rpcUrl: "...", // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
const modal = createWeb3Modal({
  data() {
    return {
      store,
      loading: true,
      modal, // include modal here
    };
  },
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: false, // Optional - false as default
  explorerRecommendedWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  ],
  enableExplorer: false,
  includeWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  ],
  
  //allWallets: "NONE",
  excludeWalletIds: [
    "browser",
    "walletconnect",
    "injected",
    "all-wallets",
  ],
  _sdkVersion: "Vue",
  disableInjectedProvider: false,
});*/

export default {
  name: "App",
  components: {
    FooterBlock,
    PlayPopup,
    LoginPopup,
    MainMenu,
    ErrorPopup,
    //HelloWorld
  },
  data() {
    return {
      store,
      loading: true,
      agree : true,
    };
  },
  methods: {
    agreeTerms() {
      Cookies.set("agree", "true");
      this.agree = true;
    },
    async loadUser() {
      const user = await getUserProfile();
      if (user != null && user.status == 200) {
        //await this.$store.dispatch('module/updateUser', user.data );
        this.store.commit("setUser", user.data);
        if (user.data.walletsWithValidNFTs?.length > 0)
          this.store.commit(
              "setUserWallet",
              user.data.walletsWithValidNFTs[0].walletAddress,
          );
        this.store.commit("setLoggedIn", true);
        this.$store.commit('setWaitingForProfile', false);
      }
      this.loading = false;
    }
  },
  async mounted() {
    this.$store.commit('setWaitingForProfile', true);
    //const detectDevice = new parser();
    const p = new UAParser();
    this.store.commit('setIsMobile', p.getDevice().type !== undefined || ['wearable', 'mobile'].includes(p.getDevice().type))
    let got = false;
    const agree = Cookies.get("agree");
    if (agree != null && agree != "") {
      this.agree = true;
    } else {
      this.agree = false;
    }

    const token = Cookies.get("token");
    if (token != null && token != "") {
      this.store.commit("setToken", token);
      this.store.commit("setRefreshToken", Cookies.get("refresh_token"));
      got = true;
    }
    
    if(!got) {
      this.loading = true;
      setTimeout(() => {
        const query = this.$route.query;
        const gameToken = query.token;
        if (gameToken != null && gameToken != "") {
          this.store.commit("setToken", gameToken);
          got = true;
          this.loadUser();
        } else {
          this.loading = false;
          this.$store.commit('setWaitingForProfile', false);
        }
      }, 50)
    } else {
      this.loadUser();
    }

    
    
  },
};
</script>

<style lang="scss">
@import "./styles/variables.sass";
@import "./styles/common.sass";
@import url("https://fonts.googleapis.com/css2?family=Risque&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Angkor&display=swap");

body {
  margin: 0px;
  padding: 0px;
  background: #251a11;
  overflow-x: hidden;
}
body {
  font-family: "Risque", serif;
  font-weight: 400;
  font-style: normal;
}
#app {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.font-ris {
  font-family: "Risque", serif;
  font-weight: 400;
  font-style: normal;
}
body .font-ang {
  font-family: "Angkor", serif;
  font-weight: 400;
  font-style: normal;
}
.cookies-modal {
  display: block;
  position: fixed;
  bottom: 0px;
  max-width: 600px;
  background: #fff;
  text-align: center;
  z-index: 50;
  box-shadow: 0px 0px 15px 6px #000;
  width: 100%;
}
.cookies-modal p {
  padding: 10px 20px;
}
.cookies-confirm {
  position: absolute;
  width: calc(100%* 4 / 12);
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  bottom: 0px;
}
.cookies-modal .cookies-confirm span {
  float: right;
  display: inline-block;
  position: relative;
  background: #83a84a;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  text-shadow: 1px 1px 0px #333;
  font-size: 20px;
}
.cookies-modal cookies-confirm:hover {
  box-shadow: 0px 0px 5px 3px #000;
}
.cookies-modal p span {
  text-decoration: underline;
  cursor: pointer;
}
.overlay {
  filter: blur(5px);
}
.darken {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}
.game-panel {
  background: url("./assets/bg2.png");
  background-repeat: repeat-x;
  width: 100%;
  min-height: 500px;
  position: relative;
  background-position-x: calc(50% + 406px);
  background-color: #e3d0c9;
}
.game-panel-inner {
  background: url("./assets/bg1.png");
  background-repeat: no-repeat;
  width: 100%;
  min-height: 600px;
  position: relative;
  background-position-x: calc(50% - 350px);
}
.content-wrap {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.content {
  max-width: 960px;
  margin: 0px auto;
  position: relative;
  z-index: 10;
}
.top-divider {
  background: url("./assets/divider.png");
  background-size: 100% 200px;
  background-repeat: repeat-x;
  background-position-x: center;
  width: 100%;
  height: 200px;
  margin-top: -75px;
  position: relative;
}
a {
  text-decoration: none;
}
.logo img {
  cursor: pointer;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: static;
  margin-left: calc(50% - 40px);
  margin-top: 300px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

w3m-modal {
  --walletconnect-display: none;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner,
.main-loading {
  position: absolute;
  /* width: 100%; */
  /* height: 100%; */
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.main-loading {
  background: #e3d0c9;
}

.texts-wrap {
  margin-left: 10px;
}
.home-title {
  font-size: 42px;
  line-height: 50px;
  display: block;
}
@media screen and (min-width: 600px) {
  .home-title {
    font-size: 50px;
    line-height: 50px;
    display: block;
  }
  .cookies-modal {
    left: calc(50% - 300px);
  }
}
@media screen and (min-width: 700px) {
  .home-title {
    font-size: 60px;
    line-height: 50px;
    display: block;
  }
}
@media screen and (min-width: 1000px) {
  .texts-wrap {
    margin-left: 0px;
  }
}
</style>
